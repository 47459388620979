import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import { initReactI18next } from "react-i18next";

// On récupère les deux denrières lettre de la locale, qui serviront souvent lors d'affichage de données traduisibles en BDD
const availableLanguages = ["EN", "FR"];
const defaultLanguage = "EN";

// Gestion des cas exceptionnels qui nécessite un changement de code
let navigatorLanguage = navigator.language.slice(-2);
if (navigatorLanguage === "US") {
    navigatorLanguage = "EN";
}

const locale =
    localStorage.getItem("i18nextLng") &&
    availableLanguages.includes(localStorage.getItem("i18nextLng"))
        ? localStorage.getItem("i18nextLng")
        : availableLanguages.includes(navigatorLanguage)
          ? navigatorLanguage
          : defaultLanguage;
i18n.locale = locale;

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: locale,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

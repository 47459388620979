import { createContext, useEffect, useState } from "react";

import { usePreventModalCloseOnBackButton } from "helpers/window";

import { Drawer } from "antd";

export const DrawerContext = createContext(null);

const LayoutDrawer = ({ children }) => {
    const [drawerData, setDrawerData] = useState();
    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => {
        if (drawerData) {
            setOpenDrawer(true);
        }
    }, [drawerData]);

    useEffect(() => {
        if (!openDrawer) {
            setTimeout(() => {
                setDrawerData(null);
            }, 500);
        }
    }, [openDrawer]);

    usePreventModalCloseOnBackButton(openDrawer, () => setOpenDrawer(false));

    return (
        <DrawerContext.Provider
            value={{ drawerData, setDrawerData, setOpenDrawer }}
        >
            {drawerData && (
                <Drawer
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer}
                    {...drawerData}
                />
            )}
            {children}
        </DrawerContext.Provider>
    );
};
export default LayoutDrawer;

export default function PasswordProtection() {
    // Pour l'env TEST, on demande un mot de passe
    if (
        process.env.REACT_APP_NODE_ENV === "test" &&
        process.env.REACT_APP_PASS_TEST &&
        process.env.REACT_APP_PASS_TEST !== ""
    ) {
        if (
            localStorage.getItem("PASS_TEST") !==
            process.env.REACT_APP_PASS_TEST
        ) {
            let pass = prompt("Password");
            while (pass !== process.env.REACT_APP_PASS_TEST) {
                pass = prompt("Password");
            }
            // Si c'est le bon mot de passe, on l'enregistre pendant 30 jours
            localStorage.setItem("PASS_TEST", pass, { expires: 30 });
        }
    }
    return null;
}

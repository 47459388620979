import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import constants from "utils/constants";

import { getCookies } from "./CookiesPreferences";

export default function GoogleAnalytics() {
    const cookies = getCookies();

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (isActive)
            ReactGA._gaCommandSendPageview(location.pathname + location.search);
    }, [location, isActive]);

    useEffect(() => {
        try {
            ReactGA.initialize(constants.googleAnalyticsTrackingId);
            // Consent mode (sans acceptation nécessaire)
            const consentState = cookies.STATISTICS ? "granted" : "denied";
            window.gtag("consent", cookies.STATISTICS ? "update" : "default", {
                ad_storage: consentState,
                ad_user_data: consentState,
                ad_personalization: consentState,
                analytics_storage: consentState,
            });
            setIsActive(true);
        } catch (err) {}
    }, [cookies]);

    return null;
}

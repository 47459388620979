import { useEffect, useState } from "react";

import { getSettingByCode } from "helpers/setting";

import CookiesBanner from "components/integrations/CookiesBanner";
import GoogleAnalytics from "components/integrations/GoogleAnalytics";
import HotjarIntegration from "components/integrations/HotjarIntegration";
import IntercomChat from "components/integrations/IntercomChat";
import MaintenancePage from "components/integrations/MaintenancePage";
import MultiLanguage from "components/integrations/MultiLanguage";
import PasswordProtection from "components/integrations/PasswordProtection";

export default function LayoutIntegrations({ children }) {
    // Maintenance Mode
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    useEffect(() => {
        getSettingByCode("MAINTENANCE_MODE").then((res) => {
            if (res === "1") {
                setMaintenanceMode(true);
            }
        });
    }, []);
    if (maintenanceMode) return <MaintenancePage />;

    return (
        <>
            {process.env.REACT_APP_NODE_ENV === "production" && (
                <>
                    <CookiesBanner />
                    <HotjarIntegration />
                    <GoogleAnalytics />
                    <IntercomChat />
                </>
            )}
            <PasswordProtection />
            <MultiLanguage />

            {children}
        </>
    );
}

/*const initGTM = () => {
    const tagManagerArgs = {
        gtmId: "GTM-TXTFWJ5",
    }; 
    TagManager.initialize(tagManagerArgs);
};*/

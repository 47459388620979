import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import Spinner from "./components/utils/Spinner";
import "./styles/index.css";

// // For Vite bundler : Clean console on HMR ( Fast-resfresh )
// if (import.meta.hot) {
//     import.meta.hot.on("vite:beforeUpdate", () => console.clear());
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Suspense fallback={<Spinner />}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Suspense>,
);

import i18next from "i18next";

import { Fragment } from "react";
import { Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Text from "components/common/Text";

import { Collapse, Divider, Flex, Form, Switch } from "antd";

const CookiesPreferences = ({ groups }) => {
    const { t } = useTranslation();

    return (
        groups.length > 0 && (
            <Flex gap={12} vertical>
                <Text bold>
                    {t("miseAJourDesPreferencesConcernantLesCookies")}
                </Text>
                <Text size="sm">
                    {t("applicationTitle") +
                        " " +
                        t(
                            "utiliseDifferentesCategoriesDeCookiesPourProposerProtegerAmeliorerEtPromouvoirSonSiteWebEtSesServicesLeDetailDeCesCategoriesEtLeursFinalitesSontExplicitesPlusBasPourEnSavoirPlusVeuillezConsulterNotre",
                        ) +
                        " "}
                    <Link
                        to="/privacy-policy"
                        target="_blank"
                        className="font-bold text-primary underline"
                    >
                        {t("politiqueDeConfidentialite").toLocaleLowerCase()}
                    </Link>
                    .
                </Text>
                {groups
                    .filter((group) => group.cookies.length > 0)
                    .map((group, i) => (
                        <Fragment key={i}>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <Collapse
                                items={[
                                    {
                                        key: i,
                                        label: (
                                            <Text
                                                bold
                                                children={
                                                    group[
                                                        "title" + i18next.locale
                                                    ]
                                                }
                                            />
                                        ),
                                        children: (
                                            <ul className="list-disc mx-6">
                                                {group.cookies
                                                    .sort((a, b) =>
                                                        a.position < b.position
                                                            ? -1
                                                            : 1,
                                                    )
                                                    .map((cookie) => (
                                                        <li key={cookie._id}>
                                                            {
                                                                cookie[
                                                                    "title" +
                                                                        i18next.locale
                                                                ]
                                                            }
                                                        </li>
                                                    ))}
                                            </ul>
                                        ),
                                        extra: (
                                            <Form.Item
                                                name={group.code}
                                                style={{
                                                    margin: 0,
                                                }}
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <Switch />
                                            </Form.Item>
                                        ),
                                    },
                                ]}
                                ghost
                            />
                            <Text size="sm">
                                {group["description" + i18next.locale]}
                            </Text>
                        </Fragment>
                    ))}
            </Flex>
        )
    );
};

export function getCookies() {
    const cookieConsent = Cookies.get("CookieConsent");
    if (!cookieConsent) return false;
    const cookies = localStorage.getItem("cookiesPreferences")
        ? JSON.parse(localStorage.getItem("cookiesPreferences"))
        : null;
    return cookies;
}

export default CookiesPreferences;

import axios from "axios";

import { Fragment, useContext, useEffect, useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import apiURL from "utils/apiURL";

import CookiesPreferences, {
    getCookies,
} from "components/integrations/CookiesPreferences";

import { ModalContext } from "layout/LayoutModal";

const CookiesBanner = () => {
    const { t } = useTranslation();

    const cookies = getCookies();
    const cookieConsent = Cookies.get("CookieConsent");

    const [isVisible, setIsVisible] = useState(true);
    const { addModalData } = useContext(ModalContext);

    // Get All groups of cookies
    const [groups, setGroups] = useState([]);
    useEffect(() => {
        axios.get(apiURL.getAllCookieGroups).then((res) => {
            if (res && res.data) {
                setGroups(res.data);
            }
        });
    }, []);

    const handleAll = (value) => {
        localStorage.setItem(
            "cookiesPreferences",
            JSON.stringify({
                STATISTICS: value,
                MARKETING: value,
            }),
        );
        setIsVisible(false);
    };

    const handleSetupCookies = () => {
        addModalData({
            key: "cookiesPreferences",
            title: t("preferencesDesCookies"),
            content: <CookiesPreferences groups={groups} />,
            handleConfirm: async (e) => {
                Cookies.set("CookieConsent", e.STATISTICS || e.MARKETING);
                localStorage.setItem(
                    "cookiesPreferences",
                    JSON.stringify({
                        STATISTICS: !!e.STATISTICS,
                        MARKETING: !!e.MARKETING,
                    }),
                );
                setIsVisible(false);
            },
            initialValues: {
                STATISTICS: true,
                MARKETING: true,
            },
        });
    };
    if (
        typeof cookieConsent !== "undefined" ||
        window.location.pathname.startsWith("/viewer-embed") ||
        window.location.pathname === "/login-action"
    )
        return null;
    return (
        <Fragment>
            <CookieConsent
                location="bottom"
                buttonText={t("toutAccepter")}
                containerClasses="cookies-banner"
                buttonWrapperClasses="m-auto"
                buttonClasses="cookies-accept-btn"
                enableDeclineButton={true}
                declineButtonText={t("refuser")}
                declineButtonClasses="cookies-decline-btn"
                onAccept={() => handleAll(true)}
                onDecline={() => handleAll(false)}
                visible={isVisible ? "show" : "hidden"}
            >
                <div className="text-black text-xs sm:text-sm">
                    {t(
                        "nousUtilisonsDesCookiesPourProposerAmeliorerProtegerEtPromouvoirNosServicesPourEnSavoirPlusConsultezNotre",
                    )}{" "}
                    <Link
                        to="/privacy-policy"
                        className="font-bold text-primary underline"
                    >
                        {t("politiqueDeConfidentialite").toLocaleLowerCase()}
                    </Link>
                    .
                    <br />
                    {t("notreOutilDeConsentementAuxCookiesVousPermetDe")}{" "}
                    <button
                        type="button"
                        className="font-bold text-primary underline"
                        onClick={handleSetupCookies}
                    >
                        {t("gererVosPreferencesPersonnelles")}
                    </button>
                    .
                </div>
            </CookieConsent>
        </Fragment>
    );
};

export default CookiesBanner;

import { useEffect } from "react";
import { Provider } from "react-redux";

import { loadUser } from "actions/auth";
import setAuthToken from "helpers/setAuthToken";

import { store } from "../store";

export default function LayoutRedux({ children }) {
    useEffect(() => {
        store.dispatch(loadUser());
    }, []);

    // Si l'utilisateur est connecté
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    return <Provider store={store}>{children} </Provider>;
}

import Hotjar from "@hotjar/browser";
import PropTypes from "prop-types";

import { useEffect } from "react";
import { connect } from "react-redux";

import { getCookies } from "./CookiesPreferences";

const HotjarIntegration = ({ auth: { user } }) => {
    const cookies = getCookies();

    useEffect(() => {
        if (cookies?.STATISTICS) {
            Hotjar.init(5199584, 6);
            // Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
            if (!!user) {
                Hotjar.identify(user._id, {
                    username: user.firstname + " " + user.lastname,
                    email: user.email,
                });
            }
        }
    }, [cookies, user]);

    return null;
};

HotjarIntegration.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, null)(HotjarIntegration);

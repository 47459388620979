import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, Modal } from "antd";

export const ModalContext = createContext(null);

const LayoutModal = ({ children }) => {
    const [modalsHolder, setModalsHolder] = useState([]);

    const addModalData = (data) => {
        setModalsHolder((prev) => [...prev, data]);
    };

    const removeModal = (key) => {
        setModalsHolder((prev) =>
            prev.filter((modalData) => modalData.key !== key),
        );
    };

    const setValidation = (key, value) => {
        setModalsHolder((prev) =>
            prev.map((modalData) => {
                if (modalData.key === key) {
                    return {
                        ...modalData,
                        validation: value,
                    };
                }
                return modalData;
            }),
        );
    };

    const setHandleConfirm = (key, handleConfirm) => {
        setModalsHolder((prev) =>
            prev.map((modalData) => {
                if (modalData.key === key) {
                    return {
                        ...modalData,
                        handleConfirm,
                    };
                }
                return modalData;
            }),
        );
    };

    return (
        <ModalContext.Provider
            value={{
                setHandleConfirm,
                addModalData,
                removeModal,
                setValidation,
            }}
        >
            {children}
            {modalsHolder.map((modalData, index) => {
                if (modalData.type === "illustrated")
                    return (
                        <IllustratedModalBase
                            modalKey={modalData.key}
                            {...modalData}
                            key={index}
                        />
                    );
                return (
                    <AsyncModalBase
                        modalKey={modalData.key}
                        {...modalData}
                        key={index}
                    />
                );
            })}
        </ModalContext.Provider>
    );
};

const AsyncModalBase = ({
    title = "",
    handleCancel = async () => {},
    handleConfirm = async () => {},
    validation = null,
    isForm = true,
    content = <div></div>,
    closable = true,
    modalKey = null,
    method = "ASYNC", // ASYNC or BLOCK
    ...props
}) => {
    if (!modalKey)
        throw new Error(
            "key is required in 'layout/LayoutModal.AsyncModalBase', You need to provide the props key in a 'addModalData' function",
        );

    const { t } = useTranslation();
    const { removeModal } = useContext(ModalContext);
    // Manage Open state
    const [isOpen, setIsOpen] = useState(true);
    const closeModal = async () => {
        await handleCancel();
        setIsOpen(false);
        setTimeout(() => {
            removeModal(modalKey);
        }, 300);
        // removeModal(modalKey);
    };
    const [isLoadingBlock, setIsLoadingBlock] = useState(false);

    useEffect(() => {
        window.history.pushState(null, "", window.location.href);
        window.addEventListener("popstate", closeModal);
        return () => {
            window.addEventListener("popstate", closeModal);
        };
    }, []);

    // Manage Form Sumbit
    const submitModal = async () => {
        // startDriveSyncMessage(); // Some modals don't require drive sync
        if (method === "ASYNC") setIsOpen(false);
        if (method === "BLOCK") setIsLoadingBlock(true);
        await handleConfirm(form.getFieldsValue());
        if (method === "BLOCK") {
            setIsLoadingBlock(false);
            setIsOpen(false);
        }
        removeModal(modalKey);
    };

    // Manage Form validation
    const [isConfirmDisabled, setIsConfirmDisabled] = useState();
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    useEffect(() => {
        if (process.env.NODE_ENV !== "production") console.log(values);
        form.validateFields({ validateOnly: true })
            .then(() => setIsConfirmDisabled(false))
            .catch((err) => {
                if (process.env.NODE_ENV !== "production")
                    console.log(err.errorFields);
                if (err.errorFields?.length === 0) {
                    setIsConfirmDisabled(false);
                } else {
                    setIsConfirmDisabled(true);
                }
            });
    }, [values, form]);

    useEffect(() => {
        setIsConfirmDisabled(validation);
    }, [validation]);

    return (
        <Modal
            title={title}
            open={isOpen}
            onCancel={closeModal}
            onOk={submitModal}
            cancelText={t("annuler")}
            cancelButtonProps={{
                variant: "outlined",
                size: "large",
            }}
            okButtonProps={{
                disabled: isForm
                    ? isLoadingBlock
                        ? true
                        : isConfirmDisabled
                    : false,
                size: "large",
                loading: isLoadingBlock,
                htmlType: "submit",
            }}
            okText={t("confirmer")}
            maskClosable={closable ? !isLoadingBlock : false}
            closable={closable ? !isLoadingBlock : false}
            footer={(e) => (isForm ? e : null)}
            modalRender={(dom) =>
                isForm ? (
                    <Form
                        initialValues={props.initialValues}
                        form={form}
                        layout="vertical"
                        name={"formModal-" + modalKey}
                        clearOnDestroy
                    >
                        {dom}
                    </Form>
                ) : (
                    dom
                )
            }
            children={content}
            width={700}
            destroyOnClose
            centered
            styles={{
                content: {
                    padding: 0,
                },
                header: {
                    padding: "16px",
                    borderBottom: "1px solid #dddddd",
                },
                body: {
                    padding: "16px",
                    maxHeight: "70vh",
                    overflow: "auto",
                },
                footer: {
                    padding: "10px 16px",
                },
            }}
            {...props}
        />
    );
};

const IllustratedModalBase = ({ title, subtitle, content }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(true);

    return (
        <Modal
            open={isOpen}
            styles={{
                content: {
                    overflow: "hidden",
                    padding: 0,
                },
            }}
            footer={null}
            onCancel={() => setIsOpen(false)}
        >
            <div className="w-full h-full md:flex md:flex-row md:min-h-[550px]">
                <figure className="relative md:w-[50%]">
                    <figcaption className="md:absolute text-white lg:text-lg w-full md:max-w-[70%] p-6 text-left bg-gradient-to-r from-teal-500 to-teal-300 md:bg-none">
                        <div className="font-bold text-3xl">{title}</div>
                        <p className="mt-4">{subtitle}</p>
                    </figcaption>
                    <div className="hidden md:block h-full w-[80%] bg-gradient-to-r from-teal-500 to-teal-300 rounded-br-[100px]"></div>
                    <img
                        src="/img/login.svg"
                        loading="lazy"
                        className="hidden md:block absolute bottom-0 left-0 w-[95%]"
                        alt=""
                    />
                </figure>
                <div className="relative md:w-[50%] p-4 md:p-0 md:flex mb-16">
                    <div className="w-full my-auto mr-4 lg:mr-12">
                        {content}
                    </div>
                    <img
                        src="/img/logo-black.svg"
                        width="50"
                        className="absolute bottom-[-56px] right-4"
                        alt={t("applicationTitle")}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default LayoutModal;

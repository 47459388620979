import {
    ACTIVATE_USER,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_SUBSCRIPTION,
    DELETE_USER_ACCOUNT,
    EDIT_USER,
} from "actions/types";

const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
};

export default function userReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case EDIT_USER:
        case CHANGE_USER_SUBSCRIPTION:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
            };

        case CHANGE_USER_PASSWORD:
            localStorage.setItem("token", payload.token);
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
            };

        case ACTIVATE_USER:
            localStorage.setItem("token", payload.token);
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
                userActivated: true,
            };

        case DELETE_USER_ACCOUNT:
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
            };

        default:
            return state;
    }
}

import i18n from "i18next";

import { useEffect } from "react";
import { useNavigate } from "react-router";

// Langues disponibles en préfix dans l'URL (vide pour EN)
export const prefixLanguages = ["", "fr"];

export default function MultiLanguage() {
    const navigate = useNavigate();

    // Gestion du préfix de la langue dans l'URL pour certaines routes (SEO)
    // On force la traduction dans la langue du préfix URL
    useEffect(() => {
        const langParam = window.location.pathname.split("/")[1];
        if (
            langParam !== "" &&
            prefixLanguages.includes(langParam) &&
            localStorage.getItem("i18nextLng") !== langParam.toUpperCase()
        ) {
            i18n.changeLanguage(langParam.toUpperCase());
            window.location.reload();
        }
    }, [navigate]);

    return <></>;
}

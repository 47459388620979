import axios from "axios";

import setAuthToken from "helpers/setAuthToken";
import apiURL from "utils/apiURL";

import {
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    USER_LOADED,
} from "./types";

// Load User

export const loadUser = () => async (dispatch) => {
    // On définit le token dans le header

    if (localStorage.token) setAuthToken(localStorage.token);

    try {
        const res = await axios.get(apiURL.authUser);

        if (res.data) {
            dispatch({
                type: USER_LOADED,
                payload: res.data,
            });
        } else {
            dispatch({
                type: AUTH_ERROR,
            });
        }
    } catch (error) {
        dispatch({
            type: AUTH_ERROR,
        });
    }
};

// Register User

export const register = (newUserDatas) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const body = JSON.stringify(newUserDatas);

    try {
        const res = await axios.post(apiURL.saveUser, body, config);

        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data,
        });

        return res;
    } catch (error) {
        dispatch({
            type: REGISTER_FAIL,
        });

        return null;
    }
};

// Login User

export const login =
    (email, password, tmpTokenLogin = false) =>
    async (dispatch) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const body = JSON.stringify({ email, password, tmpTokenLogin });

        try {
            const res = await axios.post(apiURL.authUser, body, config);

            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });

            dispatch(loadUser());

            return res.data;
        } catch (e) {
            const errors = e.response.data.errors;

            if (errors) {
                //On affiche une alerte pour toutes les érreurs possibles

                return { errors: errors };
            }

            dispatch({
                type: LOGIN_FAIL,
            });
        }
    };

// Login User by Auth Token (from public website)

export const loginByToken = (token) => async (dispatch) => {
    try {
        const res = await axios.get(apiURL.authTokenLogin + token);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
        });

        dispatch(loadUser());

        return true;
    } catch (e) {
        const errors = e.response.data.errors;

        if (errors) return { errors: errors };

        dispatch({
            type: LOGIN_FAIL,
        });
    }
};

// Logout

export const logout = () => (dispatch) => {
    dispatch({
        type: LOGOUT,
    });
};

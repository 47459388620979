import { Fragment, useEffect, useRef, useState } from "react";

const IconButton = ({
    type = "button",
    icon,
    text,
    onClick,
    active = false,
    isSuccess = false,
    isError = false,
    isNeutral = false,
    isBlackBtn = false,
    isDisabled = false,
    isSquare = false,
    isTransparent = false,
    children,
    isSmall = false,
}) => {
    const inputRef = useRef(null);

    const [isShowChildren, showChildren] = useState(false);

    useEffect(() => {
        if (children)
            document.addEventListener("click", handleClickOutside, true);

        return () => {
            if (children)
                document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (inputRef.current) {
            if (!inputRef.current.contains(e.target))
                setTimeout(() => showChildren(false));
            else showChildren(true);
        }
    };

    return (
        <Fragment>
            <button
                ref={inputRef}
                className={
                    "relative group" +
                    (!isSuccess && !isError && !isNeutral && !isBlackBtn
                        ? " hover:text-white hover:border-secondary border-2  border-primary hover:bg-secondary"
                        : !isNeutral && !isBlackBtn
                          ? " border-white hover:border-white w-[50px] h-[50px] border-4"
                          : "") +
                    (!active &&
                    !isShowChildren &&
                    !isNeutral &&
                    !isSuccess &&
                    !isError &&
                    !isBlackBtn
                        ? " bg-primary text-white"
                        : !isNeutral && !isSuccess && !isError && !isBlackBtn
                          ? " bg-white text-primary border-primary"
                          : "") +
                    (isSuccess
                        ? " bg-green-500 text-white hover:bg-green-600"
                        : isError
                          ? " bg-red-500 text-white hover:bg-red-600"
                          : "") +
                    (isNeutral
                        ? " bg-slate-100 hover:bg-gray-200 border-0 hover:text-black text-black"
                        : "") +
                    (isDisabled ? " opacity-25" : "") +
                    (isBlackBtn
                        ? " border-2 w-[50px] sm:w-[60px] h-[50px] sm:h-[60px] border-black" +
                          (active
                              ? " text-black bg-white"
                              : " bg-black text-white")
                        : "") +
                    (isSquare ? " rounded-lg" : " rounded-full") +
                    (isSmall
                        ? " w-[30px] h-[30px] text-xs" +
                          (isNeutral
                              ? " bg-slate-500 hover:bg-slate-200 border-0 text-white"
                              : "")
                        : " w-[40px] h-[40px]") +
                    (isTransparent ? " bg-transparent" : "")
                }
                type={type}
                onClick={(e) => onClick && onClick(e)}
                title={text}
                disabled={isDisabled}
            >
                <div className="flex justify-center">
                    {isSuccess && !icon ? (
                        <i
                            className="material-icons notranslate"
                            style={{
                                fontSize:
                                    isSmall && !isBlackBtn
                                        ? "1.2rem"
                                        : "1.5rem",
                            }}
                        >
                            check
                        </i>
                    ) : isError && !icon ? (
                        <i
                            className="material-icons notranslate"
                            style={{
                                fontSize:
                                    isSmall && !isBlackBtn
                                        ? "1.2rem"
                                        : "1.5rem",
                            }}
                        >
                            close
                        </i>
                    ) : (
                        <i
                            className="material-icons notranslate"
                            style={{
                                fontSize:
                                    isSmall && !isBlackBtn
                                        ? "1.2rem"
                                        : "1.5rem",
                            }}
                        >
                            {icon}
                        </i>
                    )}
                </div>
            </button>
            {children && isShowChildren && (
                <div className={isShowChildren ? " block" : " hidden"}>
                    {children}
                </div>
            )}
        </Fragment>
    );
};

export default IconButton;

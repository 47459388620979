import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { Button, Result } from "antd";

const MaintenancePage = () => {
    const { t } = useTranslation();

    return (
        <Result
            style={{
                margin: "auto",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
            icon={
                <img
                    className="m-auto"
                    src="/img/logo-black.svg"
                    width="150px"
                    alt={t("applicationTitle")}
                />
            }
            title={
                <Text className="font-shuriken" size="lg">
                    {t("applicationTitle") +
                        " " +
                        t("estActuellementEnMaintenance")}
                </Text>
            }
            subTitle={<Text size="md">{t("merciDeVotreComprehension")}</Text>}
            extra={
                <Button href="/home" size="large" type="primary">
                    {t("decouvrirBimono")}
                </Button>
            }
        />
    );
};

export default MaintenancePage;

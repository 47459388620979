import { combineReducers } from "redux";

import auth from "./auth";
import paymentTransaction from "./paymentTransaction";
import profile from "./profile";
import user from "./user";

export default combineReducers({
    auth,
    user,
    profile,
    paymentTransaction,
});

const { createHmac } = require("crypto-browserify");

// Retourne un token avec la longueur passée en paramètre
export function generateRandomToken(length) {
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        token += characters.charAt(randomIndex);
    }

    return token;
}

export function generateHMAC(secretKey, userIdentifier) {
    const hash = createHmac("sha256", secretKey ? secretKey : "")
        .update(userIdentifier)
        .digest("hex");

    return hash;
}
